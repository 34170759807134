<template>
  <div
    :ref="_uid"
    class="d-flex">
    <div class="area-video-info caption grayDark--text">
      <b>{{ user.name }} </b>&nbsp;| {{ participant.role }}

      <!-- <template v-slot:activator="{ on }"> -->
      <v-btn
        class="action-audio"
        color="white"
        fab
        absolute
        right
        top
        dark
        small
        @click="toggleAudio(index)">
        <v-icon
          v-if="participant.actions"
          color="primary">
          {{ $root.icons.action.audio_on }}
        </v-icon>
        <v-icon
          v-else
          color="primary">
          {{ $root.icons.action.audio_off }}
        </v-icon>
      </v-btn>
      <!-- </template> -->

      <!-- <template v-slot:activator="{ on }"> -->
      <v-btn
        class="action-video"
        color="white"
        fab
        absolute
        right
        top
        dark
        small
        @click="toggleVideo(index)">
        <v-icon
          v-if="participant.actions"
          color="primary">
          {{ $root.icons.action.video_on }}
        </v-icon>
        <v-icon color="primary">
          {{ $root.icons.action.video_off }}
        </v-icon>
      </v-btn>
      <!-- </template> -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      participant: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        voiceActived: true,
        videoActived: true,
        user: {}
      }
    },
    beforeMount () {
      this.$store.dispatch('user/get', this.participant.identity).then(snap => {
        this.user = snap.data() || {}
      })
    },
    mounted () {
      this.$refs[this._uid].appendChild(this.participant.attach())
    },
    methods: {
      toggleAudio (index) {
        let status = this.users[index].actions.audio
        this.users[index].actions.audio = !status
      },
      toggleVideo (index) {
        let status = this.users[index].actions.video
        this.users[index].actions.video = !status
      },
      menuClicked (routeName) {
        this.$router.push({ name: routeName })
      }
    }
  }
</script>

<style lang="sass">

</style>
