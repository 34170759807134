<template>
  <v-container class="container-page">
    <v-card
      class="elevation-0 my-0"
      color="transparent">
      <v-container
        fluid
        grid-list-xs>
        <v-layout
          row
          wrap
          class="area-video">
          <v-flex
            ref="videoMini"
            d-flex
            class="area-video-mini">
            <div class="row remote_video_container">
              <div id="remoteTrack" />
            </div>
            <div class="spacing" />
            <div class="row">
              <div id="localTrack" />
            </div>

            <v-card
              v-for="subscriber in participants"
              :key="subscriber.id"
              class="d-flex">
              <video-box :participant="subscriber" />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  import VideoBox from './VideoBox'
  import Twilio, { createLocalVideoTrack } from 'twilio-video'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      VideoBox
    },
    data () {
      return {
        openContextualArea: false,
        activedMenu: null,
        voiceActived: true,
        videoActived: true,
        loading: false,
        data: {},
        localTrack: false,
        remoteTrack: '',
        activeRoom: '',
        previewTracks: '',
        identity: '',
        participants: []
      }
    },
    computed: {
      ...mapGetters({
        userId: 'user/id',
        roomName: 'app/videoConferenceRoom'
      }),
    },
    mounted () {
      this.$store.dispatch('cases/videoConferenceToken').then(response => {
        const token = response.data.token
        let connectOptions = {
          name: this.roomName
          // logLevel: 'debug',
          // audio: true,
          // video: { width: 400 }
        }

        Twilio.connect(token, connectOptions).then(room => {
          // console.log('room', room)
          // set active toom
          this.activeRoom = room
          this.loading = false

          room.participants.forEach(participant => {
            console.log('forEach', participant)
            // let tracks = Array.from(participant.tracks.values())
            // console.log('tracks', tracks)
            // this.participants.push(tracks)
            // let previewContainer = document.getElementById('remoteTrack')
            // this.attachParticipantTracks(participant, previewContainer)
          })

          // When a Participant joins the Room, log the event.
          room.on('participantConnected', participant => {
            this.dispatchLog("Joining: '" + participant.identity + "'")
          })

          // When a Participant adds a Track, attach it to the DOM.
          room.on('trackSubscribed', (track, participant) => {
            this.dispatchLog(participant.identity + ' added track: ' + track.kind)
            track.identity = participant.identity
            track.kind === 'video' && this.participants.push(track)
            let previewContainer = document.getElementById('remoteTrack')
            this.attachTracks([track], previewContainer)
          })

          // When a Participant removes a Track, detach it from the DOM.
          room.on('trackUnsubscribed', (track, participant) => {
            this.dispatchLog(participant.identity + ' removed track: ' + track.kind)
            if (track.kind === 'video') {
              this.participants = this.participants.filter(item => item.id !== track.id)
            }
            this.detachTracks([track])
          })

          // When a Participant leaves the Room, detach its Tracks.
          room.on('participantDisconnected', participant => {
            this.dispatchLog("Participant '" + participant.identity + "' left the room")
            this.detachParticipantTracks(participant)
          })

          if (!this.localTrack) {
            createLocalVideoTrack().then(track => {
              // let localMediaContainer = document.getElementById('localTrack')
              // localMediaContainer.appendChild(track.attach())
              // console.log('createLocalVideoTrack', room.localParticipant.identity)
              // const tracks = Array.from(room.localParticipant.tracks.values())
              // const trackP = tracks.find(item => item.kind === 'video')
              track.identity = room.localParticipant.identity
              // console.log('createLocalVideoTrack', track)
              this.participants.push(track)
              this.localTrack = true
            })
          }
        })
      }).catch(e => {
        console.log('e', e)
      })
    },
    created () {
      // window.addEventListener('beforeunload', this.destroySesion)
      window.addEventListener('beforeunload', this.leaveRoomIfJoined)
    },
    beforeDestroy () {
      // window.removeEventListener('beforeunload', this.destroySesion)
      this.destroySesion()
    },
    methods: {
      dispatchLog (message) {
        console.log(message)
      },
      // Attach the Tracks to the DOM.
      attachTracks (tracks, container) {
        tracks.forEach(track => {
          track.kind !== 'video' && container.appendChild(track.attach())
        })
      },
      // Attach the Participant's Tracks to the DOM.
      attachParticipantTracks (participant, container) {
        let tracks = Array.from(participant.tracks.values())
        this.attachTracks(tracks, container)
      },
      // Detach the Tracks from the DOM.
      detachTracks (tracks) {
        tracks.forEach(track => {
          track.detach().forEach(detachedElement => detachedElement.remove())
        })
      },
      // Detach the Participant's Tracks from the DOM.
      detachParticipantTracks (participant) {
        let tracks = Array.from(participant.tracks.values())
        this.detachTracks(tracks)
      },
      // Leave Room.
      leaveRoomIfJoined () {
        if (this.activeRoom) {
          this.activeRoom.disconnect()
        }
      },
      toggleAudio (index) {
        let status = this.users[index].actions.audio
        this.users[index].actions.audio = !status
      },
      toggleVideo (index) {
        let status = this.users[index].actions.video
        this.users[index].actions.video = !status
      },
      close () {
        this.openContextualArea = false
      },
      menuClicked (routeName) {
        this.$router.push({ name: routeName })
        this.openContextualArea = true
      },
      destroySesion () {
        // this.$store.commit('videoconference/DESTROY_SESSION')
      },
      menuActived (routeName) {
        let videoConferenceId = this.$route.params.videoConferenceId
        let path = this.$route.path.split(videoConferenceId).slice(-1)
        if (path[0].length > 1) {
          this.openContextualArea = routeName === this.$route.name
        } else {
          this.openContextualArea = false
        }
      }
    }
  }
</script>

<style lang="sass">
  .container.grid-list-xs .area-video .flex
    padding: 0

  .area-video
    height: calc(100vh - 50px)

    .area-video-main
      width: 65%

      .video-bg
        width: 100%

    .action-audio
      right: 60px

    .action-video
      right: 10px

    .area-video-mini
      // flex-direction: row
      // flex-wrap: wrap
      // flex-direction: column
      flex-wrap: wrap
      // width: 35%

      .v-card
        position: relative
        flex-basis: 50% !important
        height: 50%!important
        // height: 20%!important // calcular dinamicamente essa altura se houver 2 colunas
        // flex-basis: 50%!important //2 colunas

        .video-bg
          width: 100%

        .area-video-info
          padding: 0 5px
          height: 25px
          justify-content: flex-start

    .area-video-info
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0 15px
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      background-color: rgba(255,255,255, .7)
      height: 45px

      .v-tooltip
        top: -20px

    .area-video-footer
      align-items: flex-end

      .v-icon
        // align-self: center
        padding-bottom: 35px
        padding-right: 10px

  .opened-body-contextual .area-video
    .area-video-mini
      flex-direction: column
      flex-wrap: nowrap

      .v-card
        flex-basis: 100%!important

      .area-video-footer
        // display: none!important
</style>
